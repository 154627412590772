import React from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { types } from '../../types/types';
import { Loading } from '../shared/Loading';
import { ResumeNumbers } from './ResumeNumbers';
import { DirectRoiComponent } from './DirectRoiComponent';
import { PieChartTimeZone } from './PieChartTimeZone';
import { TopMoodsAndContents } from './TopMoodsAndContents';

export const DashboardContainer = ({ setModalOpen }) => {
  const { dashboardData, statusDashboard } = useSelector(
    (state) => state.dashboardData
  );

  return (
    <div className="loadingContainer">
      {statusDashboard === types.loading ? (
        <Loading />
      ) : (
        <>
          <ReactTooltip
            backgroundColor="#FFEFE9"
            textColor="#7A829E"
            place="bottom"
          />
          {dashboardData && dashboardData.direct_roi && (
            <>
              <ResumeNumbers dashboardData={dashboardData} />
              <div className="flex mobile-column">
                <DirectRoiComponent
                  dashboardData={dashboardData}
                  setModalOpen={setModalOpen}
                />

                {dashboardData && dashboardData.data_time_zone && (
                  <div className="card-white demographics">
                    <PieChartTimeZone data={dashboardData.data_time_zone} />
                  </div>
                )}
              </div>
              <TopMoodsAndContents dashboardData={dashboardData} />
            </>
          )}
        </>
      )}
    </div>
  );
};
