import React from 'react';
import { ResumeNumbersItem } from './ResumeNumbersItem';

export const ResumeNumbers = ({ dashboardData }) => {
  return (
    <div className="dashboard__resume_numbers flex">
      <ResumeNumbersItem
        imgSrc="images/dashboard/users.svg"
        value={dashboardData.active_users}
        text="Total active users"
        className="resume-box-number blue"
      />
      <ResumeNumbersItem
        imgSrc="images/dashboard/support.svg"
        value={dashboardData.prevention_sessions}
        text="Prevention sessions"
        className="resume-box-number"
      />
      <ResumeNumbersItem
        imgSrc="images/dashboard/therapy.svg"
        value={dashboardData.therapy_sessions}
        text="Therapy sessions"
        className="resume-box-number"
      />
    </div>
  );
};
