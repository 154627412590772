import { fetchWithToken } from '../helpers/fetch';
import { types } from '../types/types';
import { addParams } from '../helpers/genericFunctions';
import { catchApiError } from '../helpers/catchApiError';

export const getDashboardData = (
  selectedTime,
  partnerSelected,
  metricSelected
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.dashboardDataLoading });
      const resp = await fetchWithToken(
        `partners/users/dashboard_data?select_time=${selectedTime}${addParams(
          partnerSelected,
          'partner_id',
          '&'
        )}${addParams(metricSelected, 'metric', '&')}`
      );

      const body = await resp.json();
      if (body.success) {
        const { data } = body;
        dispatch(setDashboardData({ data, metricSelected }));
        return data;
      } else {
        dispatch(catchApiError(body));
      }
    } catch (err) {
      dispatch(catchApiError({ error_connecting_back: true }));
    }
  };
};
const setDashboardData = (data) => ({
  type: types.setDashboardData,
  payload: data
});
