import Swal from 'sweetalert2';

const baseUrl = process.env.REACT_APP_API_URL;

const fetchNoToken = async (endPoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endPoint}`;
  const body = method !== 'GET' ? JSON.stringify(data) : null;

  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    ...(body ? {body} : {}),
  });
  if ([200, 401].includes(res.status)) return res;
  Swal.fire(
    'Error',
    'There was an error, please contact the administrator',
    'error',
  );
  return null;
};

const fetchWithToken = async (endPoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endPoint}`;
  const token = localStorage.getItem('token') || '';
  const email = localStorage.getItem('email') || '';
  const body = method !== 'GET' ? JSON.stringify(data) : null;

  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-User-Token': token,
      'X-User-Email': email,
    },
    ...(body ? {body} : {}),
  });
  if ([200, 401].includes(res.status)) return res;
  Swal.fire(
    'Error',
    'There was an error, please contact the administrator',
    'error',
  );
  return null;
};

const fetchFormData = async (endPoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endPoint}`;
  const token = localStorage.getItem('token') || '';
  const email = localStorage.getItem('email') || '';
  const body = method !== 'GET' ? data : null;
  const res = await fetch(url, {
    method,
    headers: {
      'X-User-Token': token,
      'X-User-Email': email,
    },
    ...(body ? {body} : {}),
  });
  if ([200, 401].includes(res.status)) return res;
  Swal.fire(
    'Error',
    'There was an error, please contact the administrator',
    'error',
  );
  return null;
};

export {fetchNoToken, fetchWithToken, fetchFormData};
