import { fetchWithToken, fetchNoToken } from '../helpers/fetch';
import { types } from '../types/types';
import Swal from 'sweetalert2';
import { catchApiError } from '../helpers/catchApiError';

export const startLogin = (email, password) => {
  return async (dispatch) => {
    try {
      const resp = await fetchNoToken(
        'partners/sessions',
        {
          user: { email, password }
        },
        'POST'
      );
      if (resp) {
        const body = await resp.json();
        if (body.success) {
          localStorage.setItem('email', email);
          localStorage.setItem('token', body.data.auth_token);
          localStorage.setItem('id', body.data.user.id);
          localStorage.setItem('token-init-date', new Date().getTime());
          const { id, name, locale, partners } = body.data.user;
          dispatch(
            login({
              uid: id,
              name: name,
              locale: locale,
              partners: partners
            })
          );
          return body;
        } else {
          return body;
        }
      }
    } catch (err) {
      dispatch(catchApiError({ error_connecting_back: true }));
    }
  };
};
const login = (user) => ({
  type: types.authLogin,
  payload: user
});

export const startChecking = () => {
  const email = localStorage.getItem('email') || null;
  const id = localStorage.getItem('id') || null;

  return async (dispatch) => {
    if (email && id) {
      const resp = await fetchWithToken(`partners/user?id=${id}`);
      if (resp) {
        const body = await resp.json();
        if (body.success) {
          const { id, name, locale, partners } = body.data.user;
          dispatch(
            login({
              uid: id,
              name: name,
              locale: locale,
              partners: partners
            })
          );
        } else {
          if (body.msg) Swal.fire('Error', body.msg, 'error');
          dispatch(checkingFinish());
        }
      }
    } else {
      dispatch(checkingFinish());
    }
  };
};

const checkingFinish = () => ({ type: types.authCheckingFinish });

export const startLogout = () => {
  return async (dispatch) => {
    const resp = await fetchWithToken('partners/sessions', {}, 'DELETE');
    if (resp) {
      const body = await resp.json();
      if (body) {
        localStorage.clear();
        dispatch(logout());
      } else {
        if (body.msg) {
          dispatch(catchApiError(body));
          Swal.fire('Error', body.msg, 'error');
        }
      }
    }
  };
};

export const logout = () => ({ type: types.authLogout });
