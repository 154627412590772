export const types = {
  idle: '[status] idle',
  loading: '[status] loading',
  completed: '[status] completed',
  failed: '[status] failed',

  authCheckingFinish: '[auth] Finish checking login state',
  authError: '[auth] Error login',
  authStartLogin: '[auth] Start login',
  authLogin: '[auth] Login',
  authStartTokenRenew: '[auth] Start token renew',
  authLogout: '[auth] Logout',
  login: '[auth] login',

  patientStatusStep1: 'Paid',

  dashboardDataLoading: '[dashboard] Dashboard data loading',
  setDashboardData: '[dashboard] Get dashboard data',

  getMyInfo: '[my] Get my info',

  getGraphicPatientsByTimeZoneLoading:
    '[graphic] Get graphic patients by time zone loading',
  getGraphicPatientsByTimeZone: '[graphic] Get graphic patients by time zone',

  partnersLoading: '[partners] Get partners loading',
  setPartners: '[partners] Get partners',
  partnersAddNew: '[partners] Add new partner',

  patientLoading: '[patient] Get patient loading',
  setPatient: '[patient] Get patient',

  plansLoading: '[plans] Get plans loading',
  setPlans: '[plans] Get plans',

  patientsLoading: '[patients] Get patients loading',
  setPatients: '[patients] Get patients',
  filterNicknamePatients: '[patients] Filter nickname patients',

  therapistsLoading: '[therapists] Get therapists loading',
  setTherapists: '[therapists] Get therapists',
  therapistAddNew: '[therapists] Add new therapist',

  uiOpenModal: '[ui] Open modal',
  uiCloseModal: '[ui] Close modal',
  uiOpenModalTherapists: '[ui] Open modal therapists',
  uiCloseModalTherapists: '[ui] Close modal therapists',
  uiOpenModalPartners: '[ui] Open modal partners',
  uiCloseModalPartners: '[ui] Close modal partners',

  videosessionsLoading: '[videosessions] Get videosessions loading',
  setVideosessions: '[videosessions] Get videosessions'
};
