import { ErrorMessage, Field } from 'formik';
import { capitalizeFirstLetter } from '../helpers/genericFunctions';

const FormsGroupFormik = ({ type, name, touchedAttr, errorsAttr, classes }) => {
  return (
    <div className="auth__input">
      <Field
        type={type}
        name={name}
        placeholder={capitalizeFirstLetter(name)}
        autoComplete="off"
        className={`${
          touchedAttr && errorsAttr ? 'is-invalid' : ''
        } ${classes}`}
      />
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export { FormsGroupFormik };
