import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { AppRouter } from './router/AppRouter';
import { ToastProvider } from 'react-toast-notifications';
import { useImportScript } from './hooks/useImportScript';

import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';

export const PartnersApp = () => {
  useImportScript();

  const rollbarConfig = {
    accessToken: `${process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: `${process.env.REACT_APP_ENV}`,
      client: {
        project: 'Ifeel agent chat'
      }
    }
  };

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <ToastProvider>
            <AppRouter />
          </ToastProvider>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
};
