import React from 'react';
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
Modal.setAppElement('#root');

export const HomeModal = ({ show, setModalOpen }) => {
  const closeModal = () => setModalOpen(false);
  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={200}
      className="modal no-header"
      overlayClassName="modal-fondo"
    >
      <button className="close-modal" onClick={closeModal}>
        X
      </button>
      <div className="modal__body">
        <ul>
          <li>
            <h4>Production units</h4>
            <p>
              Prevention of low efficiency working days through timely and
              effective emotional support to the team. The emotional well-being
              of the organization is an essential factor to maintain or increase
              these units.
            </p>
          </li>
          <li>
            <h4>Return on investment</h4>
            <p>
              It is calculated according to (Income Generated - Investment made)
              / Investment made and gives information about how much we are
              earning for each Euro (€) invested. If the result is less than 0,
              the investment has a negative return.
            </p>
          </li>
          <li>
            <h4>Return vs Cost Multiplier</h4>
            <p>
              It uses the ROI and the monthly investment for its calculation. As
              long as this coefficient is greater than 1, you are having a
              return in profits greater than the investment you are making.
            </p>
          </li>
          <li>
            <h4>Source</h4>
            <p>
              Estimates based on the HSE, Centre for Mental Health and the
              Deloitte MCS study (October 2017), Mental Health and employers:
              <a href="https://www2.deloitte.com/content/dam/Deloitte/uk/Documents/public-sector/deloitte-uk-mental-health-employers-monitor-deloitte-oct-2017.pdf">
                {' '}
                The case for investment:
              </a>
            </p>
          </li>
        </ul>
      </div>
    </Modal>
  );
};
