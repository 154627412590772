import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../actions/auth';

import { List } from 'phosphor-react';

import Dropdown from 'react-bootstrap/Dropdown';

export const Navbar = () => {
  const dispatch = useDispatch();
  const { partner_name, name } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(startLogout());
  };

  return (
    <div className="dashboard__navbar">
      <div className="navbar__content">
        <div className="login__logos">
          <img
            src="https://s3.eu-west-2.amazonaws.com/ifeel-media/images/Ifeel_pos_rgb@0.5x-min.png"
            alt="ifeel logo"
          />
        </div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="navbar__icon">
            {partner_name} <List size={18} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="navbar__user-info">
              <h3>{name}</h3>
            </div>
            <Dropdown.Divider />
            <Dropdown.Item href="" onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
