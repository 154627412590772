import React from 'react';
import { ItemTwoColumnsCard } from './ItemTwoColumnsCard';

export const DirectRoiComponent = ({ dashboardData, setModalOpen }) => {
  return (
    <div className="card-white direct_roi flex-auto strech-self">
      <h3 className="card-white__title">Direct ROI</h3>
      <div className="flex div-info-line">
        <ItemTwoColumnsCard
          value={dashboardData.direct_roi.employees_receiving_support}
          text="Employees received support"
          imgSrc="images/dashboard/employees_received_support.svg"
        />
        <ItemTwoColumnsCard
          value={dashboardData.direct_roi.return_on_investment_monthly}
          text="Return on investment"
          imgSrc="images/dashboard/return_investment.svg"
        />
      </div>

      <div className="flex div-info-line">
        <ItemTwoColumnsCard
          value={`${dashboardData.direct_roi.return_vs_cost_multiplier}x`}
          text="Return vs cost multiplier"
          imgSrc="images/dashboard/return_investment.svg"
        />
        <ItemTwoColumnsCard
          value={dashboardData.direct_roi.productivity_units_saved_monthly}
          text="Productivity units saved"
          imgSrc="images/dashboard/gear.svg"
        />
      </div>

      <div className="flex div-info-line">
        <ItemTwoColumnsCard
          value={dashboardData.direct_roi.productivity_units_saved}
          text="Productivity units saved (12-month pace)"
          imgSrc="images/dashboard/gear.svg"
        />
        <ItemTwoColumnsCard
          value={dashboardData.direct_roi.return_on_investment}
          text="Return on investment (12-month pace)"
          imgSrc="images/dashboard/return_cost_multiplier.svg"
        />
      </div>

      <button className="btn btn-pink" onClick={() => setModalOpen(true)}>
        More information
      </button>
    </div>
  );
};
