import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDashboardData } from '../actions/dashboard';

import { Navbar } from '../components/shared/Navbar';
import { Loading } from '../components/shared/Loading';

import { useTranslation } from 'react-i18next';
import { HomeModal } from '../components/dashboard/HomeModal';
import { DashboardHeader } from '../components/dashboard/DashboardHeader';
import { DashboardContainer } from '../components/dashboard/DashboardContainer';

const getTodayFormat = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const [momentSelected, setMomentSelected] = useState(getTodayFormat());
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingView, setLoadingView] = useState(true);
  const [partnerSelected, setPartnerSelected] = useState(null);

  const { partners } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  useEffect(
    () => handleSelectedChanged(momentSelected, partners[0], ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSelectedChanged = (momentSelected, partnerSelected, metric) => {
    dispatch(
      getDashboardData(
        momentSelected,
        partnerSelected[0],
        metric !== '' && metric !== null ? metric : ''
      )
    ).then((res) => {
      if (res && !res.success) {
        setPartnerSelected(partners.filter((p) => p[0] === res.partner_id)[0]);
        setLoadingView(false);
      }
    });
  };

  return (
    <div className="dashboard__screen dashboard__partners">
      <div className="dashboard__wrapper">
        <Navbar />
        <div className="dashboard__panel">
          {loadingView ? (
            <Loading />
          ) : (
            <div className="dashboard__content">
              <h2 className="dashboard__title">{t('dashboard_title')}</h2>
              <DashboardHeader
                momentSelected={momentSelected}
                setMomentSelected={setMomentSelected}
                handleSelectedChanged={handleSelectedChanged}
                partners={partners}
                partnerSelected={partnerSelected}
                setPartnerSelected={setPartnerSelected}
              />
              <DashboardContainer setModalOpen={setModalOpen} />
            </div>
          )}
        </div>
      </div>
      <HomeModal show={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};
