import { types } from '../types/types';

const initialState = {
  dashboardData: [],
  metricSelected: '',
  statusDashboard: types.idle,
  error: null
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.dashboardDataLoading:
      return {
        statusDashboard: types.loading
      };

    case types.setDashboardData:
      return {
        ...state,
        dashboardData: action.payload.data,
        metricSelected: action.payload.metricSelected,
        statusDashboard: types.completed
      };

    default:
      return state;
  }
};
