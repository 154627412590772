import React from 'react';

export const ItemTwoColumnsCard = ({ value, text, imgSrc }) => {
  return (
    <div className="flex start flex50">
      <img src={imgSrc} alt={text} />
      <div className="flex column number_exp">
        <span>{value}</span>
        <p>{text}</p>
      </div>
    </div>
  );
};
