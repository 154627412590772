import React from 'react';
import ReactApexChart from 'react-apexcharts';

export const PieChartTimeZone = ({ data }) => {
  const series2 = data.map((d) => d.y);
  const options2 = {
    chart: {
      type: 'donut'
      // height: 100
    },
    colors: ['#224FA7', '#FFC3C4', '#4789FF', '#E91E63', '#FF9800'],
    labels: data.map((d) => (d.name != null ? d.name : '-')),
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'top',
      floating: false,
      fontSize: '14px',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
      fontWeight: 400,
      formatter: function (seriesName, opts) {
        return `${
          Math.round(opts.w.globals.seriesPercent[opts.seriesIndex] * 10) / 10
        }% ${seriesName}`;
      },
      inverseOrder: false,
      width: undefined,
      height: 80,
      tooltipHoverFormatter: undefined,
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: '#3E59A2',
        useSeriesColors: false
      }
    }
  };

  return (
    <div>
      <h3 className="card-white__title">Demographics</h3>
      <div id="chart">
        <ReactApexChart
          options={options2}
          series={series2}
          type="donut"
          height={300}
        />
      </div>
    </div>
  );
};
