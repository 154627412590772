import React from 'react';

export const ArrowSelectDateBtn = ({ onClick, disabled, className }) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      <img
        src="images/arrow-left-blue.svg"
        alt="arrow left"
        className={className === 'next-month' ? 'rotate180' : ''}
      />
    </button>
  );
};
