import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowSelectDateBtn } from './ArrowSelectDateBtn';

export const DashboardHeader = ({
  momentSelected,
  setMomentSelected,
  handleSelectedChanged,
  partners,
  partnerSelected,
  setPartnerSelected
}) => {
  const [metricSelected, setMetricSelected] = useState(null);

  const { dashboardData } = useSelector((state) => state.dashboardData);
  const handleMonthClick = (dir) => {
    let newValue =
      dir === 'next'
        ? moment(momentSelected).add(1, 'months')
        : moment(momentSelected).subtract(1, 'months');
    setMomentSelected(newValue);
    handleSelectedChanged(newValue, partnerSelected, metricSelected);
  };

  return (
    <div className="dashboard__header flex space-between">
      <div className="flex">
        <p>{moment(momentSelected).format('MMMM')}</p>
        <ArrowSelectDateBtn
          onClick={(_) => handleMonthClick('prev')}
          disabled={moment(partnerSelected[2]).isSameOrAfter(
            moment(momentSelected)
          )}
          className="prev-month"
        />
        <ArrowSelectDateBtn
          onClick={(_) => handleMonthClick('next')}
          disabled={moment(momentSelected).isSame(new Date(), 'month')}
          className="next-month"
        />
      </div>
      <div className="flex">
        {dashboardData &&
          dashboardData.metrics &&
          dashboardData.metrics.length > 0 && (
            <div className="group height36">
              <Autocomplete
                id="partner-metric"
                options={dashboardData.metrics}
                size="small"
                getOptionLabel={(option) => option}
                style={{ width: 250 }}
                value={metricSelected}
                onChange={(_, newValue) => {
                  setMetricSelected(newValue);
                  handleSelectedChanged(
                    momentSelected,
                    partnerSelected,
                    newValue
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Metric" variant="outlined" />
                )}
              />
            </div>
          )}
        {partners && partners.length > 1 && partnerSelected && (
          <div className="group height36">
            <Autocomplete
              id="partner-metric"
              options={partners}
              size="small"
              getOptionLabel={(option) => option[1]}
              style={{ width: 250 }}
              value={partnerSelected}
              onChange={(_, newValue) => {
                if (newValue !== null) {
                  setPartnerSelected(newValue);
                  handleSelectedChanged(
                    momentSelected,
                    newValue,
                    metricSelected
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  id="partner-metric-label"
                  {...params}
                  label="Partner"
                  variant="outlined"
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
