import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { startChecking } from '../actions/auth';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { DashboardPage } from '../pages/DashboardPage';
import { LoginPage } from '../pages/LoginPage';

import { Spinner } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const { checking, uid, locale } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking && locale) {
    return (
      <div className="loading">
        <Spinner weight="duotone" size={60}>
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="4s"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="5s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"
          ></animateTransform>
        </Spinner>
        <h5>{t('waiting_connection')}</h5>
      </div>
    );
  }

  return (
    <div>
      <Router>
        <div>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={DashboardPage}
              locale="fr"
              isAuthenticated={!!uid}
            />
            <PublicRoute
              exact
              path="/login"
              component={LoginPage}
              isAuthenticated={!!uid}
            />

            <Redirect to={'/'} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};
