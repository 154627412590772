


import { types } from "../types/types";

const initialState = {
  graphics: [],
  status: types.idle,
  error: null
} 

export const graphicsTimeZoneReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case types.getGraphicPatientsByTimeZoneLoading:
      return {
        status: types.loading,
      }

    case types.getGraphicPatientsByTimeZone:
      return {
        ...state,
        graphics: action.payload.graphics,
        status: types.completed
      }

    default:
      return state;
  }
}
