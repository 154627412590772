import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { dashboardReducer } from "./dashboardReducer";
import { graphicsTimeZoneReducer } from "./graphicsTimeZoneReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  dashboardData: dashboardReducer,
  graphicsTimeZone: graphicsTimeZoneReducer
})
