import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { startLogin } from '../actions/auth';
import { Warning } from 'phosphor-react';
import { Form, Formik } from 'formik';
import { LoginSchema } from '../shemas/LoginSchema';
import { FormsGroupFormik } from '../shared/FormsGroupFormik';

const initialState = {
  email: '',
  password: ''
};

export const LoginPage = () => {
  const dispatch = useDispatch();

  const [errorLogin, setErrorLogin] = useState(false);

  const handleLogin = (values, setSubmitting) => {
    setErrorLogin(false);
    const { email, password } = values;

    dispatch(startLogin(email, password)).then((res) => {
      if (res && !res.success) {
        setSubmitting(false);
        setErrorLogin(true);
      }
    });
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__logos">
          <img
            src="https://s3.eu-west-2.amazonaws.com/ifeel-media/images/Ifeel_pos_rgb@0.5x-min.png"
            alt="ifeel logo"
          />
        </div>
        <div className="login__box-white">
          <div className="login__form">
            <h3 className="auth__title">Login</h3>
            <Formik
              initialValues={initialState}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleLogin(values, setSubmitting);
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="animate__animated animate__fadeIn animate__faster">
                  <FormsGroupFormik
                    type="text"
                    name="email"
                    touchedAttr={touched.email}
                    errorsAttr={errors.email}
                  />
                  <FormsGroupFormik
                    type="password"
                    name="password"
                    touchedAttr={touched.password}
                    errorsAttr={errors.password}
                  />
                  {errorLogin && (
                    <p className="error-text">
                      <Warning size={16} /> Incorrect email or password.
                    </p>
                  )}

                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}
                  >
                    Login
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div
            className="login__image"
            style={{
              background: `url("https://ifeel-media.s3.eu-west-2.amazonaws.com/images/react_dashboard/dashboard_login.png") no-repeat center`,
              backgroundSize: 'cover'
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
