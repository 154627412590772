import React from 'react';

export const TopMoodsAndContents = ({ dashboardData }) => {
  return (
    dashboardData &&
    dashboardData.top_tools &&
    dashboardData.top_moods && (
      <div className="flex start">
        <>
          {dashboardData.top_moods.length > 0 && (
            <div className="card-white flex33 strech-self top_moods-container">
              <h3 className="card-white__title">Top moods</h3>
              <div className="top_moods flex">
                {dashboardData.top_moods.map((m, i) => (
                  <div key={`top_mood_${i}`} className="top_mood">
                    <div className="mood_img">
                      <img src={m.image} alt={m.name} />
                    </div>
                    <p>{m.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {dashboardData.top_tools.length > 0 && (
            <div className="top_contents card-white flex-auto strech-self">
              <h3 className="card-white__title">Top contents</h3>
              <div className="flex">
                {dashboardData.top_tools.map((e, i) => (
                  <div
                    key={`top_tools_${i}`}
                    className="flex start top_content"
                  >
                    <div
                      className="img"
                      style={{
                        background: `url(${e.image}) no-repeat center`,
                        backgroundSize: 'cover'
                      }}
                    ></div>
                    <div className="top_content_text">
                      <p className="title">{e.title}</p>
                      <p className="desc">{e.type_of_exercise}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </div>
    )
  );
};
