import React from 'react';
import ReactDOM from 'react-dom';
import { PartnersApp } from './PartnersApp';
import './styles/style.scss';
import './i18n';

ReactDOM.render(
  <PartnersApp />,
  document.getElementById('root')
);
