import React from 'react';

export const ResumeNumbersItem = ({ imgSrc, value, text, className }) => {
  return (
    <div className={className} data-tip={text}>
      <img src={imgSrc} alt={text} />
      <div className="flex">
        <span>{value}</span>
        <p>{text}</p>
      </div>
    </div>
  );
};
